<template>
    <div class="page">
        <Header />

        <div class="top">
            <div class="info">
                <div class="title">{{ $t('ding-dan-xin-xi') }}</div>

                <div class="info-list">
                    <div class="order">
                        <div class="order-item">
                            <div class="text1">{{ $t('ding-gou-fu-wu') }}</div>
                            <div class="text2">
                                {{ $t('imt-ping-tai-hui-yuan') }}{{ memberInfo.name }}{{ $t('fu-wu') }}
                            </div>
                        </div>
                        <div class="order-item">
                            <div class="text1">{{ $t('ding-gou-shi-jian') }}</div>
                            <div class="text2">
                                <el-input-number
                                    :min="1"
                                    :precision="0"
                                    size="mini"
                                    v-model="num"
                                    :step="1"
                                    disabled
                                    step-strictly
                                ></el-input-number>

                                <div class="unit">{{ $t('nian') }}</div>
                            </div>
                        </div>
                        <div class="order-item">
                            <div class="text1">{{ $t('qi-ye-ming-cheng') }}</div>
                            <div class="text2">{{ getName(buyersInfo, ['chCompanyName', 'enCompanyName']) }}</div>
                        </div>
                        <div class="order-item">
                            <div class="text1">{{ $t('lian-xi-fang-shi') }}</div>
                            <div class="text2">{{ userInfo.phone }}</div>
                        </div>
                    </div>

                    <div class="money">
                        <div class="text1">{{ $t('ding-dan-jin-e') }}</div>
                        <div class="text2">{{ totalPrice }}</div>
                        <div class="text3">{{ $t('xian-shi-mian-fei') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="payInfo">
                <div class="title">
                    {{ $t('zhi-fu-fang-shi') }}
                </div>
                <div class="payList">
                    <el-radio-group v-model="payWay">
                        <el-radio :label="item.name" :key="item.name" v-for="item in payMothods">
                            <img class="radio-img" :src="item.img" alt="" />
                        </el-radio>
                    </el-radio-group>
                </div>
            </div>
        </div>

        <div class="submit">
            <div class="submit-content">
                <el-checkbox v-model="sure" :indeterminate="false" >
                    {{ $t('wo-tong-yi')
                    }}<el-link type="warning">{{ $t('imt-ping-tai-hui-yuan-fu-wu-gou-mai-xie-yi') }}</el-link>
                </el-checkbox>

                <el-button type="warning" size="small" @click="submit" :loading="loading" :disabled="!canSubmit">{{
                    $t('que-ren-ding-dan')
                }}</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import Header from '../../components/header/HeaderCustom';
export default {
    name: 'submit',
    data() {
        return {
            memberInfo: {},
            num: 1,
            payWay: '',
            payMothods: [
                {
                    name: 'WECHAT',
                    img: require('../../assets/img_zhifu_weixin.png')
                },
                {
                    name: 'ALIPAY',
                    img: require('../../assets/img_zhifu_zhifubao.png')
                },
                {
                    name: 'CARD',
                    img: require('../../assets/img_zhifu_yinlian.png')
                }
            ],
            sure: false,
            loading: false
        };
    },
    computed: {
        ...mapState(['userInfo', 'buyersInfo']),
        totalPrice() {
            if (this.memberInfo.price) {
                return this.memberInfo.price * this.num;
            } else {
                return 0;
            }
        },
        canSubmit() {
            if (this.sure && this.payWay) {
                return true;
            }
            return false;
        }
    },
    components: {
        Header
    },
    mounted() {
        if (this.$route.query.id) {
            this.$http.get('/member/get/' + this.$route.query.id).then(res => {
                this.memberInfo = res;
            });
        }
    },
    methods: {
        submit() {
            this.loading = true;

            this.$http
                .get('/orderInfo/create', {
                    memberId: this.memberInfo.id,
                    vendorId: this.buyersInfo.vendorId,
                    payMethod: this.payWay
                })
                .then(res => {
                    return this.$http.get('/vendorMember/create', {
                        orderId: res.id
                    });
                })
                .then(res => {
                    return this.$store.dispatch('getVipInfo');
                })
                .then(res => {
                    this.$message.success(this.$t('kai-tong-cheng-gong'));
                    history.back();
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables.scss';
.page {
    background-color: #f5f7fa;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.top {
    background-color: #fff;
    padding: 40px 0;
    .info {
        @include center-content(460);

        .title {
            font-size: 18px;
            font-weight: bold;
            color: #292c33;
            line-height: 25px;
        }
    }
}

.payInfo {
    @include center-content(460);
    padding-top: 50px;
    font-size: 18px;
    font-weight: bold;
    color: #292c33;
    line-height: 25px;
}

.info-list {
    display: flex;
    align-items: center;

    .order {
        flex-grow: 1;
    }

    .order-item {
        display: flex;
        align-items: center;
        height: 28px;
        padding: 8px 0;

        .text1 {
            font-size: 14px;
            color: #878d99;
            line-height: 20px;
            padding-right: 20px;
        }

        .text2 {
            font-size: 14px;
            color: #292c33;
            line-height: 20px;
            position: relative;

            .unit {
                font-size: 14px;
                font-weight: bold;
                color: #292c33;
                line-height: 28px;
                position: absolute;
                left: 65px;
                top: 0px;
            }
        }
    }
}

.money {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .text1 {
        font-size: 16px;
        font-weight: bold;
        color: #292c33;
        line-height: 22px;
    }

    .text2 {
        font-size: 35px;
        font-weight: bold;
        color: #e14948;
        line-height: 49px;
        margin-top: 4px;
        text-decoration: line-through;
        &::before {
            content: '¥';
        }
    }

    .text3 {
        font-size: 14px;
        color: #e14948;
    }
}
.el-radio-group {
    display: flex;
    padding: 20px 0;
}
.el-radio {
    background-color: #fff;
    padding: 20px 40px 20px 20px;
    display: flex;
    align-items: center;
}

.radio-img {
    width: 150px;
    height: 50px;
    display: block;
}

.submit {
    flex-grow: 1;
    padding: 20px 0;
}

.submit-content {
    @include center-content(460);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;

    .el-checkbox {
        width: 280px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
    }

    .el-button {
        width: 280px;
        height: 44px;
    }
}
</style>
<style lang="scss">
.el-input-number--mini .el-input__inner {
    padding-right: 55px;
    font-size: 14px;
    color: #292c33;
    font-weight: bold;
}
.el-checkbox__input {
    .el-checkbox__inner {
        background-color: transparent;
    }
}
</style>
